import * as React from "react"
import CommonProduct, {toggleCtrl,specialMapping,fieldOverrides,headingLookup} from "../components/commonPage"
import LoadProducts from "../components/loadProducts"
import { Link, Radio, RadioGroup,  Divider, FormGroup, FormControlLabel,Checkbox,Chip,Button, Stack , Box} from '@mui/material'
import {exportList, exportCSV} from "../components/exportList"
import createXLSX from "../components/exportXLSX"

var _ = require('underscore')

export interface LandingProps {
  productFieldOrderMeta: any,
}

const excluded_fields = {
  supportedLanguage: true,
//  subcategory: true
}

class MyListPage extends CommonProduct {

  compareCount: number = 0
  compare_lookup:any = {}
  compare_keys:any = []
  excludeKeys:any = {}
  exportEmailAddress: string
  exportChoice: string


  constructor(props)
  {
    super(props)
    const compare_info = this.getFromLocalstorage('compare')
    this.compare_lookup=compare_info.my_product_lookup
    this.compare_keys=compare_info.my_product_keys
    this.excludeKeys = {}
    this.state.showExportMenu = false
    this.state.compareProds = []
    this.exportEmailAddress=''
    this.firstRender=true
    this.exportChoice=null
    const {my_product_keys,my_product_lookup} = this.getFromLocalstorage('my_list')
    this.state.my_product_keys = my_product_keys
    this.state.my_product_lookup = my_product_lookup
  }
  
  modeOfExport =() => {
    const menu=document.querySelector<HTMLElement>('#popup_window .header + div')
    if (!!menu){
      menu.style.visibility = 'visible'
    }
    const loader= document.getElementById('loader')
    if (!!loader){
      loader.style.display = 'none'
    }
  }
  
  
  modeOfLoader =() => {
    const menu=document.querySelector<HTMLElement>('#popup_window .header + div')
    if (!!menu){
      menu.style.visibility = 'hidden'
    }
    
    const loader= document.getElementById('loader')
    if (!!loader){
      loader.style.display = 'block'
    }
  }

  loadCompareProducts(products)
  {
      this.setState({compareProds:products})

      this.setState({lastComparedProducts: products})
      this.setState({lastMyProductKeys: this.state.my_product_keys})
  
  }

  getLastLoadedProducts(){
    const ans= window.sessionStorage['lastLoadedProducts']
    
    let ret=null
    try{
      ret=JSON.parse(ans)  //FIXME when undefined
    } catch (error){
      ret=[]
    }

    return (ret)
  }


  getSessionLoadedProducts(){
    const ans= window.sessionStorage['lastLoadedProducts']

    let ret=null
    try{
      ret=JSON.parse(ans)  
    } catch (error){
      ret=[]
    }

    return (ret)
  }

 

  InterstitialContent(products:any) {

    const compare_keys_selected = this.getFromLocalstorage('compare').my_product_keys    
    const compare_keys_selected_count = this.getFromLocalstorage('compare').my_product_keys.length

    const productsToExportPDF=this.getSessionLoadedProducts()
    const productLengthForPDF = productsToExportPDF.length

    const productsLength = (!!products)?(products.length):0

    return (

  
      <Stack>
        <Box sx={{ height: 50}}/>

        <Box sx={{ml:5, mr:5}}>You can export your entire shortlist of products or download your current selected comparison.</Box>
        <Box sx={{ height: 60}}/>
        <FormGroup sx={{ml:5}}>
          <RadioGroup  sx={{ml:5, mr:5, fontFamily:'Montserrat',fontSize:14}} aria-label="exportChoice" name="row-radio-buttons-group">
            <FormControlLabel value="preventNoSelection" control={<Radio  sx={{display: 'none'}}  size="small" disableRipple={true}  color="primary" />}  label={""} />
            <FormControlLabel value="radioExportPDF" control={<Radio  onChange={this.handleChangeRadio}  size="small" disableRipple={true}  color="primary" />}  label={"Export my whole list as a PDF ("+((productLengthForPDF==0)?productsLength:productLengthForPDF)+" items)"} />
            <FormControlLabel value="radioExportCSV1"  control={<Radio  onChange={this.handleChangeRadio}  size="small" disableRipple={true}  color="primary" />} label={"Export latest comparison as a CSV file ("+ compare_keys_selected_count+" items)"} />
            <Box>
              <FormControlLabel sx={{ml:2}} value="radioExportXLSX"  control={<Radio   onChange={this.handleChangeRadio} size="small" disableRipple={true}  color="primary" />} label="Export latest comparison with Decision Rubric as an Excel file (up to 10 items) " />
              <Link  sx={{color:'017961' , fontFamily:'Montserrat',fontSize:15, fontWeight:400}}  href="https://docs.google.com/spreadsheets/d/1i5NRQGL6r8sFWFdyiBsXJY0p5UMQUe5CdAEg2nVht2M/edit#gid=591365272" rel="noopener noreferrer" target="_blank" >What does it look like?</Link>
            </Box>            

          </RadioGroup>
        </FormGroup>
        <Box sx={{ height: 90}}/>
        <Divider></Divider>
        <Box sx={{ height: 20}}/>

        <Stack direction="row">
  
        <Button className= "template template-export-button"  id="export_download" onClick={ this.exportDownload(products,this) }><Box id="box_download_label"> Download <div className="right_arrow"/></Box> </Button>
        </Stack>
        <Box sx={{ height: 50}}/>
      </Stack>
  
  
  
    );
  }

  
  private getProductsToExportPDF(products: any) {
    return this.getSessionLoadedProducts() ?? products
  }

  exportDownload(products:any,parent:any) {
    return async _ => {
      let productsToExport = parent.state.products;

      this.modeOfLoader()

      switch (parent.exportChoice){

        case "radioExportCSV1":
          exportCSV(productsToExport);
          break;
        case "radioExportXLSX":
          createXLSX(productsToExport)
          break;
        case "radioExportPDF":
          // exportList(this.getLastLoadedProducts()); 
          // exportList(this.getProductsToExportPDF(products))
          const productsToExportPDF=this.getSessionLoadedProducts()
          if (productsToExportPDF.length == 0) {
            await exportList(productsToExport)
          } else {
            await exportList(productsToExportPDF);
          }
          break;
      }
      // cleaning exportChoice
      parent.exportChoice=null

      this.modeOfExport()
      return true
      
    }
  }

  handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.exportChoice = event.target.value
  };

onChangeCheckbox(buttonNo:Number, parent:any){
  return (event:React.ChangeEvent<HTMLInputElement>) => {
    switch(buttonNo){
      case 0: parent.setState({exportPDF: true}); break;  // Cannot read properties of undefined (reading 'setState')
      case 1: parent.setState({exportCSV1: true}); break;
      case 2: parent.setState({exportCSV2: true}); break;
    }
  }

}
  
  exportMenu(products) {  // making an interstitial
    if (!(this.state.showExportMenu) )
    {
      return null
    }
  
    return <>
      <div id="popup">
      </div>
      <div id="popup_holder">
      <div id="popup_window">

      <div className="header">Export My List<i onClick={()=>this.setState({showExportMenu:false})} className="fal fa-times"></i></div>
      {this.InterstitialContent(products)}

      </div>  <div id="loader" className="compareLoader"/>

      </div>
    </>
        
  }

  render() {
    let {mv_lookups,compareProds:products,my_product_keys} = this.state

    let metaLoaded = this.metaLoaded.bind(this)
    let loadCompareProducts = this.loadCompareProducts.bind(this)

    if (!this.state.initSearch)
    {
      const compare_info = this.getFromLocalstorage('compare')
      this.compare_lookup=compare_info.my_product_lookup
      this.compare_keys=compare_info.my_product_keys
      this.excludeKeys = []
    }

    let filterLookup = {}

    let filterJSONValues = JSON.parse( (this.isBrowser && window.localStorage['filter']) || "{}" )
    for (let complexKey of Object.keys(filterJSONValues))
    {
      let [sel, fieldName, keyStr] = complexKey.split('_')
      if (filterJSONValues[complexKey])
      {
        filterLookup[keyStr] = mv_lookups.products[fieldName][keyStr]
      }
    }

    const edLvlLookup = this.state?.mv_lookups?.products?.educationalLevel

    let headingKeys = Object.keys(headingLookup)
    let headingValuesLookup = {}
    headingKeys.map( ( key, index) => {headingValuesLookup[headingLookup[key]] = key} )

    let BaseComponent = this.BaseComponentRender.bind(this)
    let controller=this

    let dataGridLookup:any = {
      title:[],
      description:[]
    }
    let dataGridFields = []

    let validationFieldLookup = {}

    let filtered_products = []

    products.map( (product,index) => {
      let product_key = 'compare_'+product.productKey
      let my_list_state = !!this.state[product_key]
      let bound_values:any = {unique_id:product_key,state:my_list_state,controller,save_group:'compare',onUpdate:()=>{this.excludeKeys[product_key]=true}}
      let toggler:toggleCtrl = new toggleCtrl(bound_values)
      let clickFunction = toggler.toggleSelection.bind(toggler)

      if (!this.excludeKeys[product_key])
      {
        filtered_products.push(product)
        dataGridLookup.title.push( <div key={product_key} className="name_info">
            <i className="fal fa-times" onClick={clickFunction}></i>
            <strong>{product.name}</strong>
            <div className="product_by">by {product.organizationName}</div>
        </div> )
      }
    })

    filtered_products.map((product,index) => {
      let product_key = 'compare_'+product.productKey
      dataGridLookup.description.push(<div key={'desc_'+product_key} className="nowrap_cells">
            <p>{product.publicDescription}</p>
        </div> )

    })

    // Initialize the grid
    headingKeys.map((headingFieldName,headingIndex)=>{
      let childFields = (!!this.filterTree[headingFieldName])?Object.keys(this.filterTree[headingFieldName]):[]
      for (let fieldName of childFields) 
      {
        dataGridFields.push(fieldName)
        dataGridLookup[fieldName] = []
      }
    })

    filtered_products.map( (product:any,index) => {
      if (!!product.validations) {
        product.validations.map( (badge, index) => {
          if (!!headingValuesLookup[badge.area]) validationFieldLookup[headingValuesLookup[badge.area]] = true
        })
      }
    })

    headingKeys.map((headingFieldName,headingIndex)=>{
      let childFields = (!!this.filterTree[headingFieldName])?Object.keys(this.filterTree[headingFieldName]):[]

      filtered_products.map( (product,index) => {
        let mv_lookup = this.createLookupFromArray(product.mv_items)

        for (let fieldName of childFields)
        {
          let SpecialFields:any = null
          let type = this.filterTree[headingFieldName][fieldName].type

          if (type == "MLI")
          {
            let exceptions = null

            if (fieldName=='educationalLevel' && !!product[fieldName] && product[fieldName].length>0)
            {
              SpecialFields = Object.keys(specialMapping).map((lookupKey,index)=>{
                  let key = edLvlLookup[lookupKey]
                  return <span key={product.productKey+"grade_ed_level_"+lookupKey} className={"property ed_grade"+((!!mv_lookup[key])?' selected':'')}>{specialMapping[lookupKey]}</span>
                })
              exceptions = specialMapping
            }

            let PropertyValues = this.showPropertyPill(fieldName, mv_lookup, product.productKey, exceptions)

            if (!SpecialFields && !PropertyValues)
            {
              dataGridLookup[fieldName].push(null)
            }
            else
            {
              dataGridLookup[fieldName].push(<>
                {SpecialFields}
                {
                  (SpecialFields!=null) &&
                  <div className="clear"/>
                }
                {
                  PropertyValues
                }
              </>)
          }
          }
          else
          if (!!type && ((type == "DataTypes.TEXT" || type.startsWith("DataTypes.STRING"))))
          {
            if (!!product[fieldName] && product[fieldName].length>0)
            {
              dataGridLookup[fieldName].push( <>
                <p>{product[fieldName]}</p>
              </>)
            }
            else
            {
              dataGridLookup[fieldName].push(null)
            }
          }
          else
          if (type == "DataTypes.INTEGER(1)")
          {
            dataGridLookup[fieldName].push(<>
                {(product[fieldName]==1)?'Yes':(product[fieldName]==0)?'No':'N/A'}
              </>)
          }
          else {
            console.log("type:",type,"not implemented")
          }

        }
      })      
    })

    for (let fieldName of dataGridFields)
    {
      let line = dataGridLookup[fieldName]
      let empty = true
      for (let cell of line)
      {
        if (!!cell)
        {
          empty = false
          break
        }
      }
      if (empty)
      {
        delete dataGridLookup[fieldName]
      }
    }

    return (
      <BaseComponent isCompare={true} controller={this} className="compare" menu="my_list" title="Compare Products | EdTech Index" callback={metaLoaded} my_product_keys={my_product_keys}>
      <h1>Compare Products ({this.compare_keys.length - Object.keys(this.excludeKeys).length})</h1>
      {this.exportMenu(products)}

      <Box>
      <Button className="template" id="export_list_button" onClick={ ()=> this.setState({showExportMenu: true}) }><Box className="exportListBox" >Export List <div className="right_arrow"/></Box></Button>
      </Box>

      <LoadProducts 
              onChange={loadCompareProducts}
              state={this.state}
              recordIds={this.compare_keys}
              initState={'initCompare'}
              ></LoadProducts>

      <div id="compare_main">
        <div id="wrapper">
          <div id="name_row">
            {
              (products.length>0) &&
              dataGridLookup.title.map((component, index) => component)
            }
          </div>
          <div className="compare_table">
            {this.Collapsable("Description", 'compare_description', !!this.state.compare_description)}
            {
              (!!this.state.compare_description) &&
                <div key={"compare_productCategories_row_desc"} id="compare_productCategories_row_desc" className="compare_row">
                  {
                    dataGridLookup.description.map((component, index) => component)
                  }
                </div>
            }
          </div>

          {
            headingKeys.map((headingFieldName,headingIndex)=>{
              let compare_heading = 'compare_'+headingFieldName
              let childFields = (!!this.filterTree[headingFieldName])?Object.keys(this.filterTree[headingFieldName]):[]
              return <div key={'compare_row_section_'+headingFieldName} className="compare_table">
                {this.Collapsable(headingLookup[headingFieldName], compare_heading, !!this.state[compare_heading])}
                {
                  (!!this.state[compare_heading]) &&
                  <>
                    {
                      childFields.map((fieldName,index) => {
                        if (!!dataGridLookup[fieldName])
                        {
                          let fieldLabel = fieldOverrides[fieldName] || this.filterTree[headingFieldName][fieldName].displayName
                          return <div key={"compare_productCategories_row_"+index} id={"compare_productCategories_row"+index} className="compare_row">
                              {
                                dataGridLookup[fieldName].map( (cell,index) => {
                                  return <div key={fieldName+'_product_'+index} className="nowrap_cells">
                                    <h3>{fieldLabel}</h3>
                                    {cell}
                                    </div>
                                })
                              }
                            </div>
                        }
                      })
                    }
                    {
                        validationFieldLookup[headingFieldName] &&
                        <div key={"compare_badges_"+headingIndex} id={"compare_badges"+headingIndex} className="compare_row">
                          {
                            filtered_products.map( (product:any,index) => {
                              const shortLbl = "compare_badges_"+headingIndex+"_prod_";
                              return <div key={shortLbl+index} className="nowrap_cells">
                                <h3>{headingLookup[headingFieldName]} Badges</h3>
                                {product.validations.map( (badge, indx) => {
                                  if (badge.area == headingLookup[headingFieldName]) {
                                    return <Chip key={shortLbl+"_badge_"+indx} label={badge.label}/>
                                  }
                                })}
                              </div>
                            })
                          }
                        </div>
                    }
                  </>
                }
                
              </div>
            })
          }

        </div>
      </div>
      </BaseComponent>
    )
  }
}

export default MyListPage
